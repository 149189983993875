@import-normalize;

a {
  text-decoration: none;
  color: #000;

  &:hover, &:active {
    text-decoration: underline;
  }
}

.layout {

}

.main {
  min-height: 100vh;
}

.footer {
  background: #ccc;

  nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul {
    list-style-type: none;
    display: flex;

    li {
      margin: 0 5vw;
    }
  }
}
