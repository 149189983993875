@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

.page.thanks {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1.thanks {
    font-family: 'Permanent Marker';
    font-size: 4em;
    text-align: center;
  }
}

