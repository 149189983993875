form.form {

  .form-group {
    .form-label, .form-input {
      display: block;
    }

    input[type=text].form-input {
      border-width: 1px;
      border-style: solid;
      border-color: #000;
      font-size: 2em;
      margin: 0.5em 0;
      padding: 0.25em;

      &:focus {
        outline: none;
      }
    }
  }
}
